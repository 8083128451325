<template>
    <v-app>
        <Header :dark="dark" :urlLogo="urlLogo" :chatbotName="chatbotName" :background-color="backgroundColor"
            :language="language" />
        <v-main app>
            <chatMessages :dark="dark" :chatbotName="chatbotName" :backgroundColor="backgroundColor"
                :idConversation="idConversation" :messages="messages" :language="language" :profileImage="urlLogo"
                :adminContactFlag="adminContactFlag" :id_user="idUser" :id_chatbot="idChatBot"
                :header_chatgpt="initialPrompt" :createdAt="createdAt" @messages="captureMessages" />

            <v-dialog v-model="privateChatbot" persistent max-width="370px">
                <v-card style="border-radius: 15px; height: fit-content; width: 370px; text-align: center;">
                    <br>
                    <p style="font-size: medium; font-weight: 600;"><v-icon color="black" class="mr-3">mdi-lock</v-icon>This
                        AI
                        Assistant is private.</p>
                    <br>
                    <p class="mx-5">Please login in to your account so you can use it inside the ChatPro AI platform.</p>
                    <br>
                    <v-card-actions>
                        <v-btn rounded color="black" class="white--text mx-auto mb-3"
                            :href="'https://app.chatpro.pro/login'" target="_blank" style="width: 200px;">Login</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-main>
    </v-app>
</template>

<script>
import Header from '../components/chatComponents/chatHeader.vue'
import chatMessages from '../components/chatComponents/chatMessages.vue'

import axiosChat from "@/logic/B2C/register_chats_chatpro/axiosChat.js";


export default {
    name: 'ChatView',
    components: {
        Header,
        chatMessages,
    },

    data() {
        return {

            //get from url id
            urlLogo: "",
            chatbotName: "",
            backgroundColor: "",
            dark: true,
            backgroundColor2: "",
            dark2: true,
            language: '', //  🇺🇸 EN  🇪🇸 ES
            idChatBot: "",
            idUser: "",
            initialPrompt: "",
            contact: "",
            adminContactFlag: false,
            createdAt: "",
            privateChatbot: null,

            //Form User
            idConversation: '',
            screenFlag: false,


            //messages
            messages: [],
            lastMessageIsUser: null
        }
    },

    async mounted() {
        this.loading = true //activa el loader
        this.idChatBot = this.$route.params.idChatbot

        //// console.log('GET idChatbot: ' + this.$route.params.idChatbot)

        const responseGetDataChatbot = await axiosChat.get_data_chatbot(this.$route.params.idChatbot)
        if (responseGetDataChatbot.data.code == "success" & responseGetDataChatbot.data.data != undefined) {
            //chatbot data
            //// console.log("Chatbot data", responseGetDataChatbot.data.data);
            this.urlLogo = responseGetDataChatbot.data.data.url_logo;
            this.chatbotName = responseGetDataChatbot.data.data.chatbot_name;
            this.backgroundColor = responseGetDataChatbot.data.data.background_color_1;
            this.dark = responseGetDataChatbot.data.data.dark_1;
            // this.backgroundColor2 = responseGetDataChatbot.data.data.background_color_2;
            // this.dark2 = responseGetDataChatbot.data.data.dark_2;
            this.language = responseGetDataChatbot.data.data.language; //  🇺🇸 EN  🇪🇸 ES
            this.idUser = responseGetDataChatbot.data.data.id_user;
            this.initialPrompt = responseGetDataChatbot.data.data.initial_prompt;
            this.createdAt = responseGetDataChatbot.data.data.created_at;
            this.privateChatbot = responseGetDataChatbot.data.data.private;
            this.loading = false
        }
        else {
            //// console.log('error ó chatbot desconocido')
        }

    },

    async created() {


        this.idConversation = this.$route.params.idConversation

        //// console.log('GET Conversation: ' + this.$route.params.idConversation)
        //// console.log()
        const responseGetConversation = await axiosChat.get_conversation(this.$route.params.idConversation)
        if (responseGetConversation.data.code == "success") {
            // console.log(responseGetConversation.data)
            this.messages = responseGetConversation.data.message.messages
            this.contact = responseGetConversation.data.message.contact

            if (this.contact === "Admin") {
                this.adminContactFlag = true;
            }
            else if (this.contact != '') {
                this.adminContactFlag = true;
            }
            else {
                this.adminContactFlag = false
            }
        }


    },

    watch: {
    },

    methods: {
        captureMessages(messages) {
            this.messages = messages
            // console.log(this.messages);

        }
    },


}
</script>

<style scoped>
.v-dialog__content {
  background-color: rgba(0, 0, 0, 0.9); /* Darker semi-transparent black */
}
</style>