
import Vue from 'vue'
import Router from 'vue-router'

// Importar las vistas
import chatNewHome from '../views/chatNewHome.vue'
import chat from '../views/chat.vue'


Vue.use(Router)

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/:idChatbot',   //ai.chatpro.us/:idChatbot
      name: 'chatHome',
      component: chatNewHome,
    },
    {
      path: '/:idChatbot/:idConversation',   //ai.chatpro.us/:idChatbot/:idConversation
      name: 'chatUI',
      component: chat,
    }
  ]
});


export default router;