<template>
    <div>
        <v-card style="width: 100%; border-radius: 0px;" elevation="0" color="#E9E9E9C5" class="mt-3 hidden-sm-and-down">
            <v-row>
                <v-col style="max-width: 10%; text-align: right;">
                    <div class="avatar-container">
                        <v-avatar class="squared-avatar" color="white" size="40" squared>
                            <img :src="profileImage" alt="Avatar"
                                style="max-width: 100%; max-height: 100%; object-fit: contain;">
                        </v-avatar>
                    </div>
                </v-col>
                <v-col>
                    <div style="max-width: 95%;" class="mx-auto mt-3">
                        <v-card-text style="text-align: justify; color: black; white-space: pre-line;">
                            <span
                                v-html="language == '🇺🇸 EN' ? 'Hi, how can I help you? 👋' : 'Hola, ¿Como puedo ayudarte? 👋'"></span>
                        </v-card-text>
                    </div>
                </v-col>
            </v-row>
        </v-card>
        <v-card style="width: 100%; border-radius: 0px;" elevation="0" color="#E9E9E9C5" class="hidden-md-and-up">
            <v-row>
                <v-col style="max-width: 10%; text-align: right;">
                    <div class="avatar-container">
                        <v-avatar class="squared-avatar" color="white" size="35" squared>
                            <img :src="profileImage" alt="Avatar"
                                style="max-width: 100%; max-height: 100%; object-fit: contain;">
                        </v-avatar>
                    </div>
                </v-col>
                <v-col>
                    <div style="max-width: 95%;" class="mx-auto mt-3">
                        <v-card-text style="text-align: justify; color: black; white-space: pre-line;">
                            <span
                                v-html="language == '🇺🇸 EN' ? 'Hi, how can I help you? 👋' : 'Hola, ¿Como puedo ayudarte? 👋'"></span>
                        </v-card-text>
                    </div>
                </v-col>
            </v-row>
        </v-card>
    </div>
</template>


<script>

export default {
    name: 'chatBotMessages',
    props: {
        chatbotName: { type: String, required: true },
        backgroundColor: { type: String, required: true },
        dark: { type: Boolean, required: true },
        messages: { type: Array, required: true },
        language: { type: String, required: true },
        profileImage: { type: String, required: true },
        lastBotMessageIndex: { type: Number, required: true },
    },

    data() {
        return {
            userChat: { uid: '002' },
            linksVector: [],
            animatedText: "",
            typingSpeed: 50
        }

    },

    created() {
        this.$set(this.$store.state, 'urlLogo', this.profileImage);
        this.$set(this.$store.state, 'backgroundColor', this.backgroundColor);
    },


    methods: {

        formatText(text) {
            const urlRegex = /((?:https?|ftp):\/\/[\n\S]+?)(?<!\.png|\.jpg|\.jpeg|\.gif|\.svg)(?:$|\s)/gi;

            const emailRegex = /([a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})/gi;
            const phoneRegex = /(\+\d{1,2}\s)?(\d{7,14})/g;
            const imageRegex = /\b(https?:\/\/\S+\.(?:jpg|jpeg|png|gif))\b/gi;



            const urlMatches = text.match(urlRegex);
            const emailMatches = text.match(emailRegex);
            const phoneMatches = text.match(phoneRegex);
            const imageMatches = text.match(imageRegex);

            const links = [];

            if (urlMatches) {
                const urls = urlMatches.map((match) => match.trim());
                const validatedUrls = urls.filter((url) => !imageRegex.test(url));
                const urlLinks = validatedUrls.map((url) => ({ type: "Link", link: url.replace(/\.+$/, ""), icon: "mdi-open-in-new" }));
                links.push(...urlLinks);

            }

            if (emailMatches) {
                const emails = emailMatches.map((match) => match.trim());
                const emailLinks = emails.map((email) => ({ type: "Email", link: `mailto:${email}`, icon: "mdi-email" }));
                links.push(...emailLinks);
            }

            if (phoneMatches) {
                const phones = phoneMatches.map((match) => match.trim());
                const phoneLinks = phones.map((phone) => ({ type: "Tel", link: `tel:${phone}`, icon: "mdi-phone" }));
                links.push(...phoneLinks);
            }

            if (imageMatches) {
                const images = imageMatches.map((match) => match.trim());
                const imageLinks = images.map((image) => ({ type: "Image", link: image, icon: "" }));
                links.push(...imageLinks);
            }

            const linksNoImages = links.filter(link => link.type !== 'Image')



            if (links.length > 0) {
                const textWithLinks = links.reduce((acc, curr) => {
                    if (curr.type == "Email") {
                        const emailLinkInText = curr.link.replace('mailto:', '');
                        return acc.replace(emailLinkInText, `<a href="${curr.link}" target="_blank">${emailLinkInText}</a>`);
                    }
                    else if (curr.type == "Image") {
                        // // console.log(curr.link)
                        return acc.replace(curr.link, `<img src="${curr.link}" alt="image" style="max-width: 250px; height: auto; display: block; margin: 0 auto; margin-top: 20px">`);
                    }
                    else if (curr.type == "Link") {
                        // // console.log(curr.link)
                        return acc.replace(curr.link, `<a href="${curr.link}" target="_blank">${curr.link}</a>`);
                    }
                    return acc;
                }, text);

                // console.log(textWithLinks)
                // console.log(links)

                return { text: textWithLinks, links: linksNoImages }; //filtra los elementos diferentes a type Image
            } else {
                return { text: text, links: [] };
            }
        },



    },
    mounted() {
    },
}
</script>

<style>
.v-btn {
    text-transform: none;
}

.typing-animation {
    overflow: hidden;
    white-space: nowrap;
}

.typing-animation:before {
    content: "";
    display: inline-block;
    vertical-align: bottom;
    height: 100%;
    width: 100%;
    background-color: #ffffff;
    /* Cambia el color según tu diseño */
    opacity: 0.3;
    animation: typingCursor 1s infinite;
}

@keyframes typingCursor {
    from {
        transform: translateX(-100%);
    }

    to {
        transform: translateX(100%);
    }
}


@media screen and (max-width: 1904px) {
    .avatar-container {
        width: 100px;

        height: 100px;

    }

    .squared-avatar {
        border-radius: 6px !important;

        overflow: hidden !important;

        margin-top: 22px;
    }

}

@media screen and (max-width: 820px) {
    .avatar-container {
        width: 100px;

        height: 100px;

    }

    .squared-avatar {
        border-radius: 6px !important;

        overflow: hidden !important;

        margin-top: 22px;
    }
}

@media screen and (max-width: 700px) {
    .avatar-container {
        width: 70px;

        height: 70px;

    }

    .squared-avatar {
        border-radius: 6px !important;

        overflow: hidden !important;

        margin-top: 22px;
    }
}

@media screen and (max-width: 600px) {
    .avatar-container {
        width: 50px;

        height: 50px;

    }

    .squared-avatar {
        border-radius: 6px !important;

        overflow: hidden !important;

        margin-top: 22px;
    }
}

@media screen and (max-width: 360px) {
    .avatar-container {
        width: 50px;

        height: 50px;

    }

    .squared-avatar {
        border-radius: 6px !important;

        overflow: hidden !important;

        margin-top: 22px;
    }
}
</style>
