/* ---------------- Nombre del Endpoint Global que utilizará el Frontend ---------------------------- */

/*
PRODUCCION */

const ENDPOINT_API_CHATBOTS = "https://prod-backend-chatbots-chatpro-itut4lj6tq-ue.a.run.app"

const ENDPOINT_API_CHATS = "https://prod-backend-chats-chatpro-itut4lj6tq-ue.a.run.app"

const ENDPOINT_API_USERS = "https://prod-backend-users-chatpro-itut4lj6tq-ue.a.run.app"

const ENDPOINT_NOTIFICATIONS = "https://prod-email-notifications-chatpro-itut4lj6tq-ue.a.run.app"
/*
DEV CLOUD */

// const ENDPOINT_API = "https://pill-main-dev-backend-ebaaxsuvaa-uc.a.run.app/api/"

/*
DESARROLLO LOCAL */
// const ENDPOINT_API = "http://localhost:3005/api/"


// const ENDPOINT_API_TRAINING_AWS = "https://smswu27kbezkkid74hh5muu4ye0wbatu.lambda-url.us-east-1.on.aws/"




module.exports = { ENDPOINT_API_CHATBOTS, ENDPOINT_API_CHATS, ENDPOINT_API_USERS, ENDPOINT_NOTIFICATIONS }
