// En tu store de Vuex (store.js o similar)
import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    chatMessage: null,
    urlLogo: null,
    backgroundColor: null,
  },
  mutations: {
    setChatMessage(state, message) {
      state.chatMessage = message;
    },
  },
  // Otras configuraciones de Vuex
});
