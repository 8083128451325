import axios from "axios";
// import authFirebase from "../../authFirebase";
const { ENDPOINT_API_USERS } = require("@/logic/globalEndpoints")


export default {


    //++++++++++++++++ Metodo GET para TRAER Info del USUARIO +++++++++++++++++++++++++
    async get_user_data(id_user) {

        const params = {
            id_user: id_user,
        };

        // const headers = {
        //     Authorization: 'Bearer ' + await authFirebase.createTokenJWT()
        // };

        const resp = await axios.get(ENDPOINT_API_USERS + "/api/b2c/data-user-notification", { params })
        return resp;
    },

}