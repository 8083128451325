<template>
    <v-footer app inset style=" background-color: transparent;">
        <v-row style="width:95%;">
            <v-form style="width:100%;" @submit.prevent="createConversation()">
                <v-progress-linear v-if="loadingFlag" indeterminate
                    :color="chatBoxColor == '#FFFFFF' ? 'black' : chatBoxColor" class="mx-auto"
                    style="max-width: 900px;"></v-progress-linear>

                <v-text-field textarea solo label="Ask anything" :color="chatBoxColor"
                    style="max-width: 920px; border-radius: 15px;" required outlined hide-details="false" class="mx-auto "
                    append-icon="mdi-send" @click:append="createConversation()" v-model="message" :disabled="loadingFlag"
                    loader-height="5"> </v-text-field>
            </v-form>
            <div style="height:38px; background-color: transparent;" class="mx-auto">
                <center>
                    <a href="https://chatpro.pro" target="_blank" style="text-decoration: none; color: black">
                        <p style="font-size:small" class="mt-1">Powered by <b>ChatPro AI.</b></p>
                    </a>
                </center>
            </div>
        </v-row>
    </v-footer>
</template>

<script>
import axiosChat from "@/logic/B2C/register_chats_chatpro/axiosChat.js";
import Vue from 'vue';
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';

Vue.use(VueToast);
export default {
    name: 'chatBotFooter',
    props: {
        chatBoxColor: { type: String, required: true },
        id_user: { type: String, required: true },
        id_chatbot: { type: String, required: true },
        header_chatgpt: { type: String, required: true },
        chatbotName: { type: String, required: true },
        createdAt: { type: String, required: true }

    },
    created() {
    },
    data() {
        return {
            message: '',
            loadingFlag: false,
            k: 0,
            idConversation: ""

        }

    },
    methods: {

        async createConversation() {
            if (this.message == '') {
                //// console.log("blank message")
            }
            else {
                this.loadingFlag = true;
                const responseCreateConversation = await axiosChat.create_conversation("", this.id_chatbot)
                if (responseCreateConversation.data.code == "success") {
                    //// console.log(responseCreateConversation);
                    this.idConversation = responseCreateConversation.data.id_session
                    // console.log('id Conversion Generated GUEST:' + this.idConversation)
                }

                // Supongamos que el usuario ha escrito un mensaje y deseas enviarlo a la ruta dinámica

                // Llama a la mutación de Vuex para almacenar el mensaje
                // this.$store.commit('setChatMessage', this.message);

                this.$set(this.$store.state, 'chatMessage', this.message);

                // console.log("El valor del vuex", this.$store.state.chatMessage);


                // console.log("Aleta si el mensaje es: ", this.message)

                // Redirige al usuario a la ruta /chat/:id con el ID del chat
                this.$router.push('/' + this.id_chatbot + '/' + this.idConversation)
            }

        }


    },
}
</script>