<template>
    <div>
        <div>
            <v-card style="width: 100%; border-radius: 0px;" elevation="0" color="#E9E9E9C5"
                class="mt-3 hidden-sm-and-down">
                <v-row>
                    <v-col style="max-width: 10%; text-align: right;">
                        <div class="avatar-container">
                            <v-avatar class="squared-avatar" color="white" size="35" squared>
                                <img :src="profileImage" alt="Avatar"
                                    style="max-width: 100%; max-height: 100%; object-fit: contain;">
                            </v-avatar>
                        </div>
                    </v-col>
                    <v-col>
                        <div style="max-width: 95%;" class="mx-auto mt-3">
                            <v-card-text style="text-align: justify; color: black; white-space: pre-line;">
                                <span
                                    v-html="language == '🇺🇸 EN' ? 'Hi, how can I help you? 👋' : 'Hola, ¿Como puedo ayudarte? 👋'"></span>
                            </v-card-text>
                        </div>
                    </v-col>
                </v-row>
            </v-card>
            <v-card style="width: 100%; border-radius: 0px;" elevation="0" color="#E9E9E9C5" class="hidden-md-and-up">
                <v-row>
                    <v-col style="max-width: 10%; text-align: right;">
                        <div class="avatar-container">
                            <v-avatar class="squared-avatar" color="white" size="35" squared>
                                <img :src="profileImage" alt="Avatar"
                                    style="max-width: 100%; max-height: 100%; object-fit: contain;">
                            </v-avatar>
                        </div>
                    </v-col>
                    <v-col>
                        <div style="max-width: 95%;" class="mx-auto mt-3">
                            <v-card-text style="text-align: justify; color: black; white-space: pre-line;">
                                <span
                                    v-html="language == '🇺🇸 EN' ? 'Hi, how can I help you? 👋' : 'Hola, ¿Como puedo ayudarte? 👋'"></span>
                            </v-card-text>
                        </div>
                    </v-col>
                </v-row>
            </v-card>
        </div>

        <div ref="messageContainer">
            <div v-for="(item) in messages" :key="item.id">
                <v-card style="height: fit-content; width: 100%; border-radius: 0px;" elevation="0" class="mt-3"
                    :color="item.uid === '001' ? '#E9E9E9C5' : ''">
                    <v-row>
                        <v-col style="max-width: 10%; text-align: right;">
                            <div class="avatar-container">
                                <v-avatar v-show="item.uid === '001' ? false : true" class="squared-avatar" color="#0360FE"
                                    size="35" squared>
                                    <span class="white--text">U</span>
                                </v-avatar>
                                <v-avatar v-show="item.uid === '001' ? true : false" class="squared-avatar" color="white"
                                    size="35" squared>
                                    <img :src="profileImage" alt="Avatar"
                                        style="max-width: 100%; max-height: 100%; object-fit: contain;">
                                </v-avatar>
                            </div>
                        </v-col>
                        <v-col>
                            <div style="max-width: 95%;" class="mx-auto mt-3">
                                <v-card-text style="text-align: justify; color: black; white-space: pre-line;">
                                    <span v-html="formatText(item.content).text"></span>
                                </v-card-text>
                                <div v-if="formatText(item.content).links.length > 0" class="ml-4">
                                    <v-btn :dark="dark" rounded small
                                        v-for="(link, index) in formatText(item.content).links" :key="index"
                                        :href="link.link" target="_blank" :color="backgroundColor" class="mt-4 mb-2 mr-2">
                                        {{ link.type }}<v-icon x-small right>{{ link.icon }}</v-icon></v-btn>
                                </div>
                            </div>

                        </v-col>
                    </v-row>

                </v-card>
            </div>
            <div v-if="showStreaming == true">
                <v-card style="height: fit-content; width: 100%; border-radius: 0px; min-height: 100px;" elevation="0"
                    class="mt-3" color="#E9E9E9C5">
                    <v-row>
                        <v-col style="max-width: 10%; text-align: right;">
                            <div class="avatar-container">
                                <v-avatar class="squared-avatar" color="white" size="35" squared>
                                    <img :src="profileImage" alt="Avatar"
                                        style="max-width: 100%; max-height: 100%; object-fit: contain;">
                                </v-avatar>
                            </div>
                        </v-col>
                        <v-col>
                            <div style="max-width: 95%;" class="mx-auto mt-3">
                                <v-card-text style="text-align: justify; color: black; white-space: pre-line;">
                                    <span v-html="formatText(incomingTextAnswer).text"
                                        v-if="showStreamingText == true"></span>
                                    <div class="loader mt-2" v-if="showStreamingText == false"></div>
                                </v-card-text>
                                <div v-if="formatText(incomingTextAnswer).links.length > 0" class="ml-4">
                                    <v-btn :dark="dark" rounded small
                                        v-for="(link, index) in formatText(incomingTextAnswer).links" :key="index"
                                        :href="link.link" target="_blank" :color="backgroundColor" class="mt-4 mb-2 mr-2">
                                        {{ link.type }}<v-icon x-small right>{{ link.icon }}</v-icon></v-btn>
                                </div>
                            </div>

                        </v-col>
                    </v-row>

                </v-card>
            </div>
            <br>
        </div>
        <v-footer app inset style=" background-color: transparent;">
            <v-row style="width:95%;">
                <v-form style="width:100%;" @submit.prevent="sendMessage(idConversation)">
                    <v-progress-linear v-if="loadingFlag" indeterminate
                        :color="backgroundColor == '#FFFFFF' ? 'black' : backgroundColor" class="mx-auto"
                        style="max-width: 900px;"></v-progress-linear>

                    <v-text-field textarea solo label="Ask anything" :color="backgroundColor"
                        style="max-width: 920px; border-radius: 15px;" required outlined hide-details="false"
                        class="mx-auto " append-icon="mdi-send" @click:append="sendMessage(idConversation)"
                        v-model="message" :disabled="loadingFlag" loader-height="5"> </v-text-field>
                </v-form>
                <div style="height:38px; background-color: transparent;" class="mx-auto">
                    <center>
                        <a href="https://chatpro.pro" target="_blank" style="text-decoration: none; color: black">
                            <p style="font-size:small" class="mt-1">Powered by <b>ChatPro AI.</b></p>
                        </a>
                    </center>
                </div>
            </v-row>
        </v-footer>

        <!-- get user personal data -->
        <v-dialog class="mt-4" v-model="contactFormFlag" max-width="500px" persistent
            v-if="contactFormFlag && !adminContactFlag">
            <v-card :color="'white'" style="border-radius: 10px; max-width: 500px;" class="mx-auto">
                <v-app-bar flat dense color="white">
                    <v-toolbar-title style="color: black; font-size: small;">
                        Share how to contact you
                    </v-toolbar-title>

                    <v-spacer></v-spacer>

                    <v-btn small color="black" icon @click="closeContactForm()">
                        <v-icon small>mdi-close</v-icon>
                    </v-btn>
                </v-app-bar>
                <v-card-text style="text-align: justify; color: black;">Email</v-card-text>
                <v-form style="width: 100%;" @submit.prevent="addUserContactData()">

                    <v-text-field type="email" dense solo :color="backgroundColor" required outlined :rules="emailRules"
                        @click:append="addUserContactData()" @input="validateEmail" v-model="userContact"
                        placeholder="user@example.com" class="ml-3 mr-3 custom-text-field"> </v-text-field>

                </v-form>
                <v-card-actions class="">
                    <v-spacer></v-spacer>
                    <v-btn rounded :color="backgroundColor" :dark="dark" :disabled="!isValidEmail || loadingContactData"
                        :loading="loadingContactData" class="white--text mr-1" small
                        @click="addUserContactData()">Submit<v-icon right x-small>mdi-send</v-icon></v-btn>
                </v-card-actions>
            </v-card>

        </v-dialog>
    </div>
</template>


<script>
import Vue from 'vue';
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';

import axiosChat from "@/logic/B2C/register_chats_chatpro/axiosChat.js";
import emailNotifications from "@/logic/emailNotifications";
import axiosB2C_users from "@/logic/B2C/register_user_chatpro/axios"


Vue.use(VueToast);

export default {
    name: 'chatBotMessages',
    props: {
        chatbotName: { type: String, required: true },
        backgroundColor: { type: String, required: true },
        dark: { type: Boolean, required: true },
        idConversation: { type: String, required: true },
        messages: { type: Array, required: true },
        language: { type: String, required: true },
        profileImage: { type: String, required: true },
        adminContactFlag: { type: Boolean, required: true },
        id_user: { type: String, required: true },
        id_chatbot: { type: String, required: true },
        header_chatgpt: { type: String, required: true },
        createdAt: { type: String, required: true }
    },

    data() {
        return {
            userChat: { uid: '002' },
            linksVector: [],
            animatedText: "",
            typingSpeed: 50,

            //variables for get contact data
            userContact: "",
            emailRules: [
                (value) => !value || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value) || 'E-mail must be valid'
            ],
            isValidEmail: false,
            loadingContactData: false,
            contactFormFlag: false,
            showStreaming: false,
            showStreamingText: false,
            incomingTextAnswer: "",
            discontinued: false,
            message: '',
            loadingFlag: false
        }

    },

    updated() {
        this.$refs.messageContainer.scrollTop = this.$refs.messageContainer.scrollHeight;
        window.scrollTo(0, document.body.scrollHeight);

    },

    created() {
        this.firstMessage(this.$store.state.chatMessage)
    },

    methods: {

        firstMessage(firstMsm) {

            if (firstMsm == null) {
                // console.log(null)
            }
            else {
                this.message = firstMsm
                this.sendMessage(this.idConversation)
            }
        },

        async sendMessage(idConversation) {

            if (this.message == '') {
                //// // console.log("blank message")
            }
            else {
                this.loadingFlag = true;
                const messageAux = this.message;
                const responseSendMessage = await axiosChat.send_message(idConversation, messageAux, "User", "002", "user")

                this.message = "";
                if (responseSendMessage.data.code == "success") {
                    // // console.log(responseSendMessage);
                }

                this.$emit('messages', responseSendMessage.data.message.messages);
                this.showStreaming = true;


                // console.log('POST enviar mensaje a: ' + idConversation)

                // const payload = {
                //     id_user: this.id_user,
                //     chatbot: this.id_chatbot,
                //     initial_prompt: this.header_chatgpt,
                //     message_question: messageAux
                // };



                // console.log("Initial prompt: " + payload.initial_prompt)
                // console.log("Question: " + payload.message_question)

                // console.log(this.createdAt)
                var creationDate = new Date(this.createdAt);
                creationDate.setHours(0, 0, 0, 0); // Establece la hora, los minutos, los segundos y los milisegundos en cero

                // // console.log("ALETA - Fecha de creación FORMATEADA: ", creationDate);

                var realeaseDate = new Date("2023-09-17T00:00:00.000Z"); // Obtiene la fecha y hora actual
                realeaseDate.setHours(0, 0, 0, 0); // Establece la hora, los minutos, los segundos y los milisegundos en cero

                // // console.log("ALETA - Fecha launch: ", realeaseDate)


                let responseAnswerChatGPT = null

                //Version 1.0
                if (creationDate < realeaseDate) {
                    this.discontinued = true;
                    // console.log("ChatPro AI v1.0")
                    responseAnswerChatGPT = await axiosChat.answer_question(idConversation, this.id_user, this.id_chatbot, this.header_chatgpt, messageAux)
                    // console.log("ChatGPT_V1.0: " + responseAnswerChatGPT.data)

                    if (responseAnswerChatGPT.data.answer === "MESSAGE_LIMIT_REACHED") {
                        this.k = this.k + 1;
                        Vue.$toast.open({
                            message: '<p style="font-family: roboto;">Message credits limit reached!</p>',
                            type: 'error',
                            position: 'top',
                            duration: 10000,
                        });
                        // // // console.log(this.k)
                        // envio de correo al dueño del Chatbot
                        if (this.k === 1) {
                            //Si K es igual 1 envia un correo al dueño, pa no enviar muchos correos
                            // // // console.log("se envia correo a: ", this.id_user)

                            const resp_data_user = await axiosB2C_users.get_user_data(this.id_user)
                            // // // console.log(resp_data_user.data)

                            if (resp_data_user.data.code == 'success') {

                                const responseEmail = await emailNotifications.limit_reached(resp_data_user.data.username, resp_data_user.data.user_email)
                                console.log(responseEmail.data)
                                this.loadingFlag = false;

                            } else {
                                // // // console.log("ERROR API= ", resp_data_user)
                                this.loadingFlag = false;
                            }


                        }

                        this.loadingFlag = false;
                    }
                    else {
                        //guarda en la base de datos        
                        const responseSendAnswer = await axiosChat.send_message(idConversation, responseAnswerChatGPT.data.answer, this.chatbotName + " - ChatGPT", "001", "assistant")
                        if (responseSendAnswer.data.code == "success") {
                            // //// // console.log(responseSendMessage);
                        }

                        this.$emit('messages', responseSendAnswer.data.message.messages);
                        this.loadingFlag = false;
                        this.showStreaming = false
                        this.showStreamingText = false
                    }
                }
                //Version 2.0
                else {
                    this.discontinued = false;

                    // console.log("ChatPro AI v2.0")
                    try {

                        const responseAnswerChatGPT = await fetch('https://prod-backend-answers-chatpro-itut4lj6tq-ue.a.run.app/multiple-sources/streaming/answer', {
                            method: 'POST', // Cambia el método de solicitud a POST
                            headers: {
                                'Content-Type': 'application/json', // Especifica el tipo de contenido
                            },
                            body: JSON.stringify({
                                id_chatbot: this.id_chatbot,
                                id_user: this.id_user,
                                temperature: 0,
                                question: messageAux,
                                header_chatgpt: this.header_chatgpt
                            }), // Convierte el objeto en una cadena JSON
                        });


                        if (!responseAnswerChatGPT.body) {
                            console.error('No response body found.');
                            return;
                        }

                        const reader = responseAnswerChatGPT.body.getReader();

                        let done = false;
                        // console.log("ChatGPT_v2.0: ")


                        this.showStreamingText = true
                        while (!done) {
                            const { done: readerDone, value } = await reader.read();

                            if (readerDone) {
                                done = true;
                            }

                            // Convert the Uint8Array to text using TextDecoder
                            const text = new TextDecoder().decode(value);
                            // Concatenate the text into the incomingTextAnswer property
                            this.incomingTextAnswer += text;
                            // Process and handle the value as needed.
                            // console.log(text);
                        }
                    } catch (error) {
                        console.error('Error:', error);
                    }

                    if (this.incomingTextAnswer === "MESSAGE_LIMIT_REACHED") {
                        this.k = this.k + 1;
                        Vue.$toast.open({
                            message: '<p style="font-family: roboto;">Message credits limit reached!</p>',
                            type: 'error',
                            position: 'top',
                            duration: 10000,
                        });
                        // // // console.log(this.k)
                        // envio de correo al dueño del Chatbot
                        if (this.k === 1) {
                            //Si K es igual 1 envia un correo al dueño, pa no enviar muchos correos
                            // // // console.log("se envia correo a: ", this.id_user)

                            const resp_data_user = await axiosB2C_users.get_user_data(this.id_user)
                            // // // console.log(resp_data_user.data)

                            if (resp_data_user.data.code == 'success') {

                                const responseEmail = await emailNotifications.limit_reached(resp_data_user.data.username, resp_data_user.data.user_email)
                                console.log(responseEmail.data)
                                this.loadingFlag = false;

                            } else {
                                // // // console.log("ERROR API= ", resp_data_user)
                                this.loadingFlag = false;
                            }


                        }

                        this.loadingFlag = false;
                    }
                    else {
                        const responseSendAnswer = await axiosChat.send_message(idConversation, this.incomingTextAnswer, this.chatbotName + " - ChatGPT", "001", "assistant")
                        if (responseSendAnswer.data.code == "success") {
                            // //// // console.log(responseSendMessage);
                            this.showStreaming = false;
                            this.showStreamingText = false;
                        }

                        this.$emit('messages', responseSendAnswer.data.message.messages);
                        this.loadingFlag = false;
                        this.incomingTextAnswer = "";
                        if (this.userContact == ""){
                            this.contactFormFlag = true
                        }
                    }
                }


            }
        },

        formatText(text) {
            const urlRegex = /((?:https?|ftp):\/\/[\n\S]+?)(?<!\.png|\.jpg|\.jpeg|\.gif|\.svg)(?:$|\s)/gi;

            const emailRegex = /([a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})/gi;
            const phoneRegex = /(\+\d{1,2}\s)?(\d{7,14})/g;
            const imageRegex = /\b(https?:\/\/\S+\.(?:jpg|jpeg|png|gif))\b/gi;



            const urlMatches = text.match(urlRegex);
            const emailMatches = text.match(emailRegex);
            const phoneMatches = text.match(phoneRegex);
            const imageMatches = text.match(imageRegex);

            const links = [];

            if (urlMatches) {
                const urls = urlMatches.map((match) => match.trim());
                const validatedUrls = urls.filter((url) => !imageRegex.test(url));

                // Filtrar los elementos que no contienen ".jpg" ni ".png"
                const urlsFiltradasRe = validatedUrls.filter(url => !url.includes('.jpg') && !url.includes('.png') && !url.includes('.jpeg') && !url.includes('.gif') && !url.includes('.svg'));

                // // console.log("Sin filtrar:", validatedUrls)
                // // console.log("Filtradas", urlsFiltradasRe)

                const urlLinks = urlsFiltradasRe.map((url) => ({ type: "Link", link: url.replace(/\.+$/, ""), icon: "mdi-open-in-new" }));
                links.push(...urlLinks);

            }

            if (emailMatches) {
                const emails = emailMatches.map((match) => match.trim());
                const emailLinks = emails.map((email) => ({ type: "Email", link: `mailto:${email}`, icon: "mdi-email" }));
                links.push(...emailLinks);
            }

            if (phoneMatches) {
                const phones = phoneMatches.map((match) => match.trim());
                const phoneLinks = phones.map((phone) => ({ type: "Tel", link: `tel:${phone}`, icon: "mdi-phone" }));
                links.push(...phoneLinks);
            }

            if (imageMatches) {
                const images = imageMatches.map((match) => match.trim());
                const imageLinks = images.map((image) => ({ type: "Image", link: image, icon: "" }));
                links.push(...imageLinks);
            }

            const linksNoImages = links.filter(link => link.type !== 'Image')





            if (links.length > 0) {
                const textWithLinks = links.reduce((acc, curr) => {
                    if (curr.type == "Email") {
                        const emailLinkInText = curr.link.replace('mailto:', '');
                        return acc.replace(emailLinkInText, `<a href="${curr.link}" target="_blank">${emailLinkInText}</a>`);
                    }
                    else if (curr.type == "Image") {
                        // // console.log(curr.link)
                        return acc.replace(curr.link, `<img src="${curr.link}" alt="image" style="max-width: 250px; height: auto; display: block; margin: 0 auto; margin-top: 20px">`);
                    }
                    else if (curr.type == "Link") {
                        // // console.log(curr.link)
                        return acc.replace(curr.link, `<a href="${curr.link}" target="_blank">${curr.link}</a>`);
                    }
                    return acc;
                }, text);

                return { text: textWithLinks, links: linksNoImages }; //filtra los elementos diferentes a type Image
            } else {
                return { text: text, links: [] };
            }
        },

        async addUserContactData() {
            this.loadingContactData = true //activa loader del boton
            // console.log(this.userContact, this.idConversation)

            const responseUpdateContactConversation = await axiosChat.update_contact_data(this.idConversation, this.userContact)
            if (responseUpdateContactConversation.data.code == "success") {
                this.loadingContactData = false //Desactiva loader del boton
                this.contactFormFlag = false //oculta el form
                Vue.$toast.open({
                    message: '<p style="font-family: roboto;">User data updated!</p>',
                    type: 'success',
                    position: 'top',
                    duration: 3000
                });
            }
            else {
                this.loadingContactData = false //Desactiva loader del boton
                this.contactFormFlag = true //Muestra el form
                Vue.$toast.open({
                    message: '<p style="font-family: roboto;">Something failed please try again!</p>',
                    type: 'error',
                    position: 'top',
                    duration: 3000
                });
            }

        },

        validateEmail() {
            const emailRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/
            this.isValidEmail = emailRegex.test(this.userContact)
        },

        closeContactForm() {
            this.contactFormFlag = false
        }
    },


    computed: {
        chatMessage() {
            return this.$store.state.chatMessage;
        },
    },

    mounted() {

    },
}
</script>

<style>
.v-btn {
    text-transform: none;
}

.custom-text-field input {
    font-size: 13px;
    /* Adjust this value as needed */
}

@media screen and (max-width: 1904px) {
    .avatar-container {
        width: 100px;

        height: 100px;

    }

    .squared-avatar {
        border-radius: 6px !important;

        overflow: hidden !important;

        margin-top: 22px;
    }

}

@media screen and (max-width: 820px) {
    .avatar-container {
        width: 100px;

        height: 100px;

    }

    .squared-avatar {
        border-radius: 6px !important;

        overflow: hidden !important;

        margin-top: 22px;
    }
}

@media screen and (max-width: 700px) {
    .avatar-container {
        width: 70px;

        height: 70px;

    }

    .squared-avatar {
        border-radius: 6px !important;

        overflow: hidden !important;

        margin-top: 22px;
    }
}

@media screen and (max-width: 600px) {
    .avatar-container {
        width: 50px;

        height: 50px;

    }

    .squared-avatar {
        border-radius: 6px !important;

        overflow: hidden !important;

        margin-top: 22px;
    }
}

@media screen and (max-width: 360px) {
    .avatar-container {
        width: 50px;

        height: 50px;

    }

    .squared-avatar {
        border-radius: 6px !important;

        overflow: hidden !important;

        margin-top: 22px;
    }
}


.loader {
    width: 35px;
    aspect-ratio: 4;
    --_g: no-repeat radial-gradient(circle closest-side, #6a6a6a 90%, #0000);
    background:
        var(--_g) 0% 50%,
        var(--_g) 50% 50%,
        var(--_g) 100% 50%;
    background-size: calc(100%/3) 100%;
    animation: l7 1s infinite linear;
}

@keyframes l7 {
    33% {
        background-size: calc(100%/3) 0%, calc(100%/3) 100%, calc(100%/3) 100%
    }

    50% {
        background-size: calc(100%/3) 100%, calc(100%/3) 0%, calc(100%/3) 100%
    }

    66% {
        background-size: calc(100%/3) 100%, calc(100%/3) 100%, calc(100%/3) 0%
    }
}
</style>
