<template>
  <v-app-bar app style="align-items: center;" :color="backgroundColor" :dark="dark">
    <v-toolbar-title>{{ chatbotName }}</v-toolbar-title>

    <v-spacer></v-spacer>

    <v-btn icon>
      <v-icon>mdi-sync</v-icon>
    </v-btn>
  </v-app-bar>
</template>
  
<script>
export default {
  name: "chatBotHeader",
  props: {
    urlLogo: { type: String, required: true },
    chatbotName: { type: String, required: true },
    backgroundColor: { type: String, required: true },
    dark: { type: Boolean, required: true },
    language: { type: String, required: true }
  },
  data() {
    return {
      online: ''
    };
  },
  methods: {
  },
  watch: {
  },
};
</script>

<style scoped></style>
