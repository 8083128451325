import axios from "axios";
// import authFirebase from "../../authFirebase";
// import ENDPOINT_NOTIFICATIONS from "@/logic/globalEndpoints";
const { ENDPOINT_NOTIFICATIONS } = require("@/logic/globalEndpoints")


export default {

    //++++++++++++++++ Metodo POST para REGISTRAR UN NUEVO USUARIO A CHATPRO +++++++++++++++++++++++++
    async welcome_email(username, email_user) { 
        const user_data_email = {
            username: username,
            email_user: email_user
        };

        const resp = await axios.post(ENDPOINT_NOTIFICATIONS + "/api/v1/sendEmail/welcome", user_data_email)
        return resp;
    },

    //++++++++++++++++ Metodo POST para ENVIAR NOTIFIACIÓN DE LIMITE ALCANZADO +++++++++++++++++++++++++
    async limit_reached(username, email_user) { 
        const limit_reached_payload = {
            username: username,
            email_user: email_user
        };

        const resp = await axios.post(ENDPOINT_NOTIFICATIONS + "/api/v1/sendEmail/limitReached", limit_reached_payload)
        return resp;
    },

}